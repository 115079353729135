import HomeComponent from "@/components/HomeComponent.vue";
import AboutComponent from "@/components/AboutComponent.vue";
import ProjectsComponent from "@/components/ProjectsComponent.vue";
import SingleProjectComponent from "@/components/SingleProjectComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";

export const routes = [
    { path: '/', component: HomeComponent, name: 'home' },
    { path: '/about', component: AboutComponent, name: 'about' },
    { path: '/projects', component: ProjectsComponent, name: 'projects' },
    { path: '/projects/:name', component: SingleProjectComponent, name: 'projects-single' },
    { path: '/contact', component: ContactComponent, name: 'contact' },
];