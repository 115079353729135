import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from "vue-router";
import { routes } from '@/router';
import { createPinia } from 'pinia';
import { Vue3Mq } from "vue3-mq";

const router = createRouter({
  history: createWebHistory(),
  routes
});

const pinia = createPinia();

const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(Vue3Mq);

app.mount('#app');
