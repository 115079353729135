<template>
  <div id="app">
    <div class="app__wrapper">
      <MqResponsive target="md-">
        <MobileHeader :mobileMenu="mobileMenu" class="mobile-header" @close-menu="mobileMenu = false" />
      </MqResponsive>
      <section :class="['main-section-wrapper', {showMobile: mobileMenu}]">
        <div class="overlay">
          <MqResponsive target="md+">
            <HeaderComponent />
          </MqResponsive>
          <MqResponsive target="md-">
            <div class="mobile-page-header">
              <button class="mobile-icon">
                <IconMenu2 color="white" size="20" @click.prevent="toggleMobileMenu" />
              </button>
              <h1 class="mobile-logo">Henry Morrow</h1>
            </div>
            
          </MqResponsive>
          <Suspense>
            <router-view v-slot="{ Component }">
              <Transition>
                <component :is="Component" />
              </Transition>
            </router-view>
            <template #fallback>
              <div>Loading...</div>
            </template>
          </Suspense>
        </div>
        <MqResponsive target="md+">
          <video id="background-video" autoplay loop muted poster="../src/assets/images/background-poster.png">
            <source src="../src/assets/videos/background-video.mp4" type="video/mp4">
          </video>
        </MqResponsive>
        
      </section>
    </div>
  </div>
</template>

<script>
import { MqResponsive } from "vue3-mq";
import HeaderComponent from './components/HeaderComponent.vue';
import MobileHeader from "./components/MobileHeader.vue";
import { IconMenu2 } from '@tabler/icons-vue';
export default {
  name: 'app',
  components: {
    HeaderComponent,
    MobileHeader,
    MqResponsive,
    IconMenu2
},
  data() {
    return {
      transitionName: 'slide',
      mobileMenu: false
    }
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    }
  },
}
</script>

<style lang="scss">
@import './assets/stylesheets/reset.scss';
@import './assets/stylesheets/fonts.scss';

$primary:                 #002244;  /* Dark Blue/Blue Zodiac Backgrounds, text */
$primary-opaque:          rgba(0, 34, 68, .6);
$secondary:               #F46A1F;  /* Orange/Flamingo       Buttons, icons, highlights, subheaders */
$white:                   #F3F4F1;  /* White/Cararra         Backgrounds, text, icons */
$grey:                    #555555;  /* Grey                  text, icons */
$secondary-buttons:       #8FABB8;  /* Teal/Bali Hai         Buttons */
$accents:                 #B87F53;  /* Green/Muddy Waters    Panel backgrounds, success buttons */
$danger:                  #F44336;  /* red                   Error buttons and text */
$warning:                 #ff9800;  /* warning Orange        Warning buttons and text */
$success:                 #4caf50;  /* Success Green         Success buttons and text */
$footer:                  #333333;  /* Black                 Footer Background */


body {
  margin: 0;
  background-color: $primary;
}

#app {
  // font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100vh;
}

.app__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 1px;
}

.main-section-wrapper {
  height: calc(100% - 60px);
  color: $white;
  margin: 29px 30px 30px;
  position: relative;
  overflow: hidden;
  transition: transform 0.35s;
  background: $primary;
}

h1 {
    font-size: 42px;
    padding-bottom: 10px;
    // font-family: 'Barlow Condensed', Helvetica, Arial, sans-serif;
    color: $white;
}

.mobile-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px 0;
}

.mobile-logo,
.mobile-icon {
  display: none;
}

@media (max-width: 768px) {
  .mobile-page-header {
    background: $primary;
    z-index: 50;
    position: relative;
  }

  .mobile-logo, 
  .mobile-icon {
    display: inline-block;
  }
}

@media (max-width: 400px) {
  .mobile-logo {
    font-size: 28px;
  }
}

.showMobile {
  transform: translate(50%);
}

.mobile-icon {
  position: relative;
  background: transparent;
  border: none;
  outline: none;
}

#background-video {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 1px solid $secondary;
  background: $primary-opaque;
  z-index: 100;
}

.slider {
  position: absolute;
  top: 0;
  right: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity .5s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
