<template>
    <section class="about-section">
        <div class="right-section">
            <h1>Working on something big?</h1>
            <h1>Perfect.</h1>
            <p>Ever worked with someone who was afraid of challenge? The kind of guy who needs clear cut direction and plenty of extra motivation? Well… that’s not me. And if that’s the kind of developer you’re looking for, I’d be happy to refer you to some of my colleagues.</p>
            <p>On the other hand, if you're in the market for an ambitious front end developer who’s hungry for new challenges then you’ve come to the right place.</p>
            <p>I’ve spent the last few years helping companies turn their ideas into clean, engaging, responsive digital experiences. Experiences their customers love to use.</p>
            <p>I’d be happy to help you do the same.</p>
        </div>
    </section>
</template>

<script setup lang="ts">

</script>

<style scoped>
.about-section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.right-section {
    width: 25%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    line-height: 20px;
}

h1, p {
    margin-bottom: 15px;
}

h1 {
    font-size: 32px;
}

@media (max-width: 768px) {
  .about-section {
    height: calc(100% - 100px);
  }
  .right-section {
    width: 100%;
    justify-content: flex-start;
    padding: 40px 20px 20px;
    text-align: center;
    height: 100%;
    overflow: auto;
    position: relative;
    top: 30px;
  }

  h1 { 
    font-size: 24px;
    line-height: 36px;
  }
}
</style>