<template>
    <header class="header">
        <div class="logo">
            <h1>Henry Morrow</h1>
            <sub>Developer - Engineer - Problem Solver</sub>
        </div>
        <RouterLink :class="{selected: route.name === 'home'}" to="/">Home</RouterLink>
        <RouterLink :class="{selected: route.name === 'about'}" to="/about">About</RouterLink>
        <RouterLink :class="{selected: route.name === 'projects' || route.name === 'projects-single'}" to="/projects">Projects</RouterLink>
        <RouterLink :class="{selected: route.name === 'contact'}" to="/contact">Contact</RouterLink>
    </header>
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute()
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/palette.scss';
    .header {
        position: absolute;
        width: 500px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        a {
            text-decoration: none;
            color: $white;
            transition: color .35s;
            margin-bottom: 20px;
            max-width: 80px;

            &:hover {
                color: $secondary;
            }
        }

        a.selected {
            color: $secondary;

            &:hover {
                color: $white;
            }
        }
    }

    .logo {
        h1 {
            font-size: 42px;
            padding-bottom: 10px;
            // font-family: 'Barlow Condensed', Helvetica, Arial, sans-serif;
        }

        sub {
            font-size: 18px;
            // font-family: 'Montserrat-Light', Helvetica, Arial, sans-serif;
        }

        margin-bottom: 25px;
    }
</style>