<template>
  <section class="home-section">
      <div class="right-section">
          <p>I'm a Michigan-based software engineer who loves solving problems. I believe in building engaging experiences that provide the wow to users. It's my mission to expand the possibilites of building software and make it accessible to all.</p>
      </div>
  </section>
</template>

<script setup>


</script>
<!-- Fonts Montserrat and Open Sans -->
<style scoped>
.home-section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.right-section {
    width: 10%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    line-height: 20px;
    padding-right: 10px;

    
}

@media (max-width: 768px) {
  .right-section {
    width: 100%;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
}

h1, p {
    margin-bottom: 15px;
}
</style>
